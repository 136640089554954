/* global swal */

function error(message) {
	return swal('Erro!', String(message), 'error');
}

function alert(message) {
	return swal('Atenção!', String(message), 'warning');
}

function success(message) {
	return swal('Obrigado!', String(message), 'success');
}

function confirm(title, text) {
	return swal({
		title,
		text,
		type: 'success',
		allowOutsideClick: false,
		allowEscapeKey: false,
		confirmButtonColor: '#3085d6',
		confirmButtonText: 'Ok'
	});
}
function confirmEnc(title,text,url){
	return confirm(title,text).then(()=>{
		window.location = url;
	});
}
function loading(title) {
	return swal({
		title,
		allowOutsideClick: false,
		allowEscapeKey: false,
		allowEnterKey: false,
		onOpen: () => {
			swal.showLoading();
		}
	});
}

function confirmAction(title, text, type, confirmButtonText){
	return swal({
		title,
		text,
		type,
		allowOutsideClick: false,
		allowEscapeKey: false,
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText
	});
}

function bufferAction(title, text, type, confirmButtonText){
	return swal({
		title,
		text,
		type,
		showCancelButton: false,
		allowOutsideClick: false,
		allowEscapeKey: false,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText
	});
}


// Exportar funções
window.loading = loading;
window.bufferAction = bufferAction;
window.error = error;
window.alert = alert;
window.success = success;
window.confirm = confirm;
window.confirmAction = confirmAction;
window.confirmEnc = confirmEnc;
